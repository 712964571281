@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
}